import { useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient, useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import useUser from "hooks/useUser";
import {
  getModules,
  deleteModule,
  createModule,
  editModule,
} from "./services";

import {
  getCourses,
} from "../courses/services";

import {
  getUsers,
} from "../users/services";

import {
  getCategories
} from '../categories/services'

import removeNil from "lib/removeNil";
import NoDataFound from "components/LottieFiles/NoDataFound"
import { Spacing } from "components/Layout/Spacing";
import { Title } from "components/Typography";
import Layout from "components/Layout";
import Modal from "components/Layout/Modal";
import Form from "./Form";
import List from "components/List";
import { Button } from "components/Form";
import { FixedTop } from "components/Layout/Styles";
import { GET_COURSES, GET_MODULES, GET_USERS, GET_CATEGORIES } from "config/queries";

export default function App() {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { access } = useUser((state) => state.user);

  const [state, setState] = useState({
    page: 0,
    overlay: { visible: false, new: false },
    currentModule: null,
    resetForm: false,
  });

  useEffect(() => {
    if (!state.overlay.visible) {
      setState(prevState => ({
        ...prevState,
        currentModule: null
      }));
    }
  }, [state.overlay.visible]);


  const moduleList = useQuery([GET_MODULES, state.page], () =>
    getModules({
      page: state.page,
    })
  );

  const courseList = useQuery([GET_COURSES], () =>
    getCourses({
        page: 0,
        limit:1000
      })
  );

  const teacherList = useQuery([GET_USERS], () =>
    getUsers({
    accessToken: access.token,
    page: 0,
    role:'teacher',
      limit:1000
    })
  );

  
  const categoryList = useQuery([GET_CATEGORIES], () =>
    getCategories({
    accessToken: access.token,
    page: 0,
    limit:1000
    })
  );
  
  const mutationOptions = {
    onSuccess: () => {
      toast.success(t("update.success"));
      queryClient.invalidateQueries(GET_MODULES);
      setState((prevState) => ({
        ...prevState,
        resetForm: true,
        overlay: { visible: false, new: false },
        currentModule: null
      }));
    },
    onError: () => {
      toast.error(t("update.error"));
    },
  };

  const onNewMutation = useMutation((data) => createModule(data, access.token), mutationOptions);
  const onEditMutation = useMutation((data) => editModule(data, state.currentModule._id, access.token), mutationOptions);
  const onDeleteMutation = useMutation((categoryId) => deleteModule(categoryId, access.token), {
    ...mutationOptions,
    onError: (err) => {
      toast.error(t("update.error"));
      console.error(err);
    },
  });

  const onNew = useCallback(async (data) => {
    onNewMutation.mutate(removeNil({ ...data }));
  }, [onNewMutation]);

  const onEdit = useCallback(async (data) => {
    onEditMutation.mutate(removeNil({ ...data }));
  }, [onEditMutation]);

  const onDelete = useCallback((categoryId) => {
    onDeleteMutation.mutate(categoryId);
  }, [onDeleteMutation]);

  const onListClick = useCallback((item) => {
    setState((prevState) => ({
      ...prevState,
      overlay: { visible: true, new: false },
      currentModule: item,
    }));
  }, []);

  return (
    <Layout>
      <Spacing bottom={"lg"} />
      <Title>{t("modules")}</Title>

      {moduleList?.data?.results && moduleList.data.results[0] ? (
        <List
          totalPages={moduleList.data.totalPages}
          currentPage={state.page}
          onChangePage={(number) => setState((prevState) => ({ ...prevState, page: number }))}
          items={moduleList.data.results}
          onDeleteBtn={onDelete}
          onClick={onListClick}
        />
      ) : <NoDataFound />
        
    }

      <FixedTop>
        <Button onClick={() => setState((prevState) => ({ ...prevState, overlay: { visible: true, new: true } }))}>
          {t("form.add")} {t("module")}
        </Button>
      </FixedTop>

      <Modal
        visible={state.overlay.visible}
        setVisible={(visible) => setState((prevState) => ({ ...prevState, overlay: { visible, new: prevState.overlay.new } }))}
      >
        <Form
          onSubmit={state.overlay.new ? onNew : onEdit}
          buttonLabel={t(state.overlay.new ? "form.save" : "form.edit")}
          resetForm={state.resetForm}
          defaultValues={state.currentModule}
          courseList={courseList?.data?.results}
          teacherList={teacherList?.data?.results}
          categoryList={categoryList?.data?.results}
        />
      </Modal>
    </Layout>
  );
}